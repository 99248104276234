<template>
  <div>
    <a-badge :color="color"></a-badge>
    <span :style="{ color }">{{ value ? trueName : falseName }}</span>
  </div>
</template>

<script>
export default {
  name: 'BooleanStatusBadge',
  props: {
    falseName: { type: String, default: '未监控' },
    trueName: { type: String, default: '监控中' },
    value: { type: Boolean, default: false }
  },
  computed: {
    color () {
      return this.value ? '#00D886' : '#F35F71'
    }
  }
}
</script>
