import request from '@/utils/request'

const urlPrefix = '/projects'

export function getProject (id) {
  return request.get(`${urlPrefix}/${id}`)
}

export function updateProject (id, data) {
  return request.put(`${urlPrefix}/${id}`, data)
}

export function deleteProject (id) {
  return request.delete(`${urlPrefix}/${id}`)
}

export function getProjectList (params) {
  return request.get(urlPrefix, { params })
}

export function createProject (data) {
  return request.post(urlPrefix, data)
}

export function getProjectTopology (id) {
  return request.get(`${urlPrefix}/${id}/topology`)
}

export function getProjectAuthorizedUserList (id) {
  return request.get(`${urlPrefix}/${id}/authorized-users`)
}
