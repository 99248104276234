<template>
  <div>
    <source-overview source-type="web"></source-overview>

    <a-form-model :model="form">
      <a-row :gutter="16">
        <a-col :xs="24" :sm="12" :xl="4">
          <a-form-model-item prop="project">
            <project-select v-model="form.project" :label-in-value="true"></project-select>
          </a-form-model-item>
        </a-col>
        <a-col :xs="24" :sm="12" :xl="4">
          <a-form-model-item prop="name">
            <a-input v-model="form.name" :allow-clear="true" :max-length="128" placeholder="请输入接口名称"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :xs="24" :sm="12" :xl="4">
          <a-form-model-item>
            <a-button icon="search" type="primary" @click="search">查询</a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>

    <a-card :bordered="false">
      <a-space style="margin-bottom: 16px;">
        <template v-if="permission.create">
          <a-button icon="plus" type="primary" @click="$refs.createForm.show()">创建</a-button>
          <create-form ref="createForm" @ok="() => { this.form.page = 1; this.fetch() }"></create-form>
        </template>

        <template v-if="permission.update">
          <a-button
            :disabled="disabled"
            icon="edit"
            type="link"
            @click="$refs.batchUpdateForm.show(rowSelection.selectedRowKeys)"
            :style="{ color: disabled ? '' : '#faad14' }"
          >
            编辑
          </a-button>
          <batch-update-form ref="batchUpdateForm" @ok="() => { this.fetch(); this.rowSelection.selectedRowKeys = [] }"></batch-update-form>
        </template>
      </a-space>

      <a-table
        :columns="columns"
        :data-source="dataSource"
        :loading="loading"
        :pagination="false"
        row-key="id"
        :row-selection="rowSelection"
        :scroll="{ x: scrollX }"
        @change="change"
        style="margin-bottom: 16px;"
      >
        <template slot="name" slot-scope="record">
          <a-tooltip placement="topLeft" :title="record.name">
            <a @click="$router.push({ name: 'WebDetail', params: { id: record.id } })">{{ record.name }}</a>
          </a-tooltip>
        </template>
        <template slot="url" slot-scope="record">
          <a-tooltip placement="topLeft" :title="record">{{ record }}</a-tooltip>
        </template>
        <template slot="project" slot-scope="record">
          <a-tooltip v-if="record" placement="topLeft" :title="record.name">{{ record.name }}</a-tooltip>
          <span v-else>-</span>
        </template>
        <template slot="monitored" slot-scope="record">
          <boolean-status-badge :value="record"></boolean-status-badge>
        </template>
        <template slot="operation" slot-scope="record">
          <a v-if="permission.update" @click="$refs.updateForm.show(record.id)" style="color: #faad14;">
            <a-space style="user-select: none;">
              <a-icon type="edit"></a-icon>
              <span>编辑</span>
            </a-space>
          </a>
          <template v-if="permission.delete">
            <a-divider type="vertical"></a-divider>
            <a-popconfirm ok-type="danger" title="是否确定删除？" @confirm="confirm(record.id)">
              <a-icon slot="icon" type="question-circle-o" style="color: #f5222d;"></a-icon>
              <a style="color: #f5222d;">
                <a-space style="user-select: none;">
                  <a-icon type="delete"></a-icon>
                  <span>删除</span>
                </a-space>
              </a>
            </a-popconfirm>
          </template>
        </template>
      </a-table>
      <update-form v-if="permission.update" ref="updateForm" @ok="fetch"></update-form>

      <div style="height: 32px;">
        <span v-if="rowSelection.selectedRowKeys.length" style="line-height: 32px;">{{ `已选中 ${rowSelection.selectedRowKeys.length} 条` }}</span>
        <a-pagination
          v-model="form.page"
          :pageSize="form.pageSize"
          :show-size-changer="true"
          :show-total="t => `共 ${t} 条`"
          :total="total"
          @change="page => { this.form.page = page; this.fetch() }"
          @showSizeChange="(_, pageSize) => { this.form.page = 1; this.form.pageSize = pageSize; this.fetch() }"
          style="float: right;"
        ></a-pagination>
      </div>
    </a-card>
  </div>
</template>

<script>
import {
  deleteWeb,
  getWebList
} from '@/api/web'
import BooleanStatusBadge from '@/components/badge/BooleanStatusBadge'
import ProjectSelect from '@/components/select/ProjectSelect'
import SourceOverview from '@/components/SourceOverview'
import { hasPermission } from '@/utils'
import UpdateForm from '../modules/UpdateForm'
import BatchUpdateForm from './modules/BatchUpdateForm'
import CreateForm from './modules/CreateForm'

export default {
  name: 'WebList',
  components: {
    BatchUpdateForm,
    BooleanStatusBadge,
    CreateForm,
    ProjectSelect,
    SourceOverview,
    UpdateForm
  },
  data () {
    return {
      form: {
        project: undefined,
        name: '',
        monitored: undefined,
        page: 1,
        pageSize: 10
      },
      columns: [
        { title: '接口名称', width: 200, ellipsis: true, scopedSlots: { customRender: 'name' } },
        { dataIndex: 'url', title: 'URL', width: 200, ellipsis: true, scopedSlots: { customRender: 'url' } },
        { dataIndex: 'project', title: '所属系统', width: 200, ellipsis: true, scopedSlots: { customRender: 'project' } },
        {
          dataIndex: 'monitored',
          title: '监控状态',
          width: 160,
          fixed: 'right',
          scopedSlots: { customRender: 'monitored' },
          defaultFilteredValue: [],
          filters: [
            { text: '监控中', value: 'true' },
            { text: '未监控', value: 'false' }
          ],
          filterMultiple: false
        },
        { title: '操作', width: 160, fixed: 'right', scopedSlots: { customRender: 'operation' } }
      ],
      dataSource: [],
      total: 0,
      loading: false,
      rowSelection: {
        selectedRowKeys: [],
        onChange: selectedRowKeys => {
          this.rowSelection.selectedRowKeys = selectedRowKeys
        }
      }
    }
  },
  computed: {
    permission () {
      return {
        create: hasPermission('web.create'),
        update: hasPermission('web.update'),
        delete: hasPermission('web.delete')
      }
    },
    scrollX () {
      let sum = 0
      this.columns.forEach(column => { sum += column.width })
      return sum
    },
    disabled () {
      return this.rowSelection.selectedRowKeys.length === 0
    }
  },
  mounted () {
    const params = this.$route.params
    if (params.monitored) {
      this.form.monitored = params.monitored
      this.columns.filter(column => {
        if (column.dataIndex === 'monitored') column.defaultFilteredValue = [params.monitored]
      })
    }
    if (params.project) this.form.project = params.project
    this.fetch()
  },
  methods: {
    fetch () {
      const params = {
        page: this.form.page,
        page_size: this.form.pageSize
      }
      if (this.form.project) params.project_id = this.form.project.key
      if (this.form.name) params.name = this.form.name
      if (this.form.monitored) params.monitored = this.form.monitored
      this.loading = true
      getWebList(params).then(res => {
        const data = res.data
        this.dataSource = data.data
        this.total = data.total
      }).finally(() => {
        this.loading = false
      })
    },
    search () {
      this.form.page = 1
      this.rowSelection.selectedRowKeys = []
      this.fetch()
    },
    change (_, filters) {
      this.form.monitored = filters.monitored[0]
      this.fetch()
    },
    confirm (id) {
      deleteWeb(id).then(res => {
        this.$message.success(res.message)
        if (this.total - 1 <= (this.form.page - 1) * this.form.pageSize) {
          if (this.form.page > 1) this.form.page -= 1
        }
        this.fetch()
      })
    }
  }
}
</script>
