<template>
  <a-modal :confirm-loading="loading" title="创建接口" :visible="visible" :width="720" @ok="ok" @cancel="reset">
    <a-form-model
      ref="form"
      :label-col="{ span: 4, style: { textAlign: 'left' } }"
      :model="form"
      :rules="rules"
      :wrapper-col="{ span: 20 }"
    >
      <a-form-model-item label="接口名称" prop="name">
        <a-input v-model="form.name" :max-length="128" placeholder="请输入接口名称"></a-input>
      </a-form-model-item>
      <a-form-model-item label="所属系统" prop="project_id">
        <project-select v-model="form.project_id"></project-select>
      </a-form-model-item>
      <a-form-model-item label="接口 URL" prop="url">
        <a-input v-model="form.url" :max-length="128" placeholder="请输入接口 URL"></a-input>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { createWeb } from '@/api/web'
import ProjectSelect from '@/components/select/ProjectSelect'

export default {
  name: 'CreateForm',
  components: { ProjectSelect },
  data () {
    return {
      loading: false,
      visible: false,
      form: {
        name: '',
        project_id: undefined,
        url: ''
      },
      rules: {
        name: [{ message: '请输入接口名称', required: true, trigger: 'blur' }],
        project_id: [{ message: '请选择系统', required: true, trigger: 'change' }],
        url: [
          { message: '请输入接口 URL', required: true, trigger: 'blur' },
          { message: '请输入正确的接口 URL', type: 'url', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    show () {
      this.visible = true
    },
    reset () {
      this.$refs.form.resetFields()
      this.visible = false
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          createWeb(this.form).then(res => {
            this.$message.success(res.message)
            this.$emit('ok')
            this.reset()
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>
