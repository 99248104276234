<template>
  <a-card
    :body-style="{ padding: '0 24px 24px 24px' }"
    :bordered="false"
    :head-style="{ border: 'none' }"
    :hoverable="true"
    title="总览"
  >
    <div style="margin-bottom: 16px; text-align: center;">
      <svg-icon :icon-name="sourceType" class-name="source-icon"></svg-icon>
    </div>

    <a-row :gutter="16" style="text-align: center;">
      <a-col :span="12">
        <a-statistic title="监控中" :value="count.monitored" :value-style="{ fontSize: '36px', color: '#00d985' }"></a-statistic>
      </a-col>
      <a-col :span="12">
        <a-statistic title="未监控" :value="count.unmonitored" :value-style="{ fontSize: '36px', color: '#f25f71' }"></a-statistic>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import svgIcon from '@/components/SvgIcon'
import { getSourceCountFunc } from '@/utils'

export default {
  name: 'CountCard',
  components: { svgIcon },
  props: {
    sourceType: { type: String, required: true }
  },
  data () {
    return {
      count: {
        monitored: 0,
        unmonitored: 0
      }
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      const countFunc = getSourceCountFunc(this.sourceType)
      const params = {}
      countFunc(params).then(res => {
        const data = res.data
        if (data.data.length !== 0) data.data.forEach(e => { this.count[e.name] = e.value })
        else this.count = { monitored: 0, unmonitored: 0 }
      })
    }
  }
}
</script>

<style lang="less">
.source-icon {
  height: 100px;
  color: #1890ff;
}
</style>
