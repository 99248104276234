<template>
  <div id="type-chart">
    <a-empty v-if="empty" style="padding-top: 20px; color: #bfbfbf;"></a-empty>
  </div>
</template>

<script>
import { Chart } from '@antv/g2'
import { getSourceCountFunc } from '@/utils'

export default {
  name: 'TypeChart',
  props: {
    sourceType: { type: String, required: true }
  },
  data () {
    return {
      chart: null,
      empty: false
    }
  },
  mounted () {
    this.chart = new Chart({ container: 'type-chart', autoFit: true })
    this.chart.coordinate('theta', { radius: 0.75, innerRadius: 0.6 })
    this.chart.tooltip({ showTitle: false, showMarkers: false })
    this.chart
      .interval()
      .adjust('stack')
      .position('value')
      .color('name')
    this.chart.interaction('element-highlight-by-color')
    this.fetch()
  },
  computed: {
    count_by () {
      switch (this.sourceType) {
        case 'host':
          return 'os_type'
        case 'server_device':
        case 'storage_device':
          return 'vendor'
        case 'web':
          return 'protocol'
        case 'ssl_certificate':
          return 'issuer'
        default:
          return 'type'
      }
    },
    countFunc () {
      return getSourceCountFunc(this.sourceType)
    }
  },
  methods: {
    fetch () {
      const params = { count_by: this.count_by }
      this.countFunc(params).then(res => {
        const data = res.data
        if (data.data.length !== 0) {
          this.chart.changeVisible(true)
          this.empty = false
        } else {
          this.chart.changeVisible(false)
          this.empty = true
        }
        this.chart.data(data.data)
        this.chart.forceFit()
        this.chart.render()
      })
    }
  }
}
</script>
