<template>
  <a-row :gutter="16">
    <a-col :xs="24" :md="12" :xl="6" style="margin-bottom: 16px;">
      <count-card :source-type="sourceType"></count-card>
    </a-col>

    <a-col :xs="24" :md="12" :xl="6" style="margin-bottom: 16px;">
      <a-card
        :body-style="{ padding: '0 24px 24px 24px' }"
        :bordered="false"
        :head-style="{ border: 'none' }"
        :hoverable="true"
        :title="title"
      >
        <a-statistic>
          <type-chart slot="formatter" :source-type="sourceType" style="height: 200px;"></type-chart>
        </a-statistic>
      </a-card>
    </a-col>
    <a-col :xs="24" :md="24" :xl="12" style="margin-bottom: 16px;">
      <alert-event-card :source-type="sourceType"></alert-event-card>
    </a-col>
  </a-row>
</template>

<script>
import AlertEventCard from './modules/AlertEventCard'
import CountCard from './modules/CountCard'
import TypeChart from './modules/TypeChart'

export default {
  name: 'SourceOverview',
  components: {
    AlertEventCard,
    CountCard,
    TypeChart
  },
  props: {
    sourceType: { type: String, required: true }
  },
  computed: {
    title () {
      switch (this.type) {
        case 'host':
          return '主机类型'
        case 'middleware':
          return '中间件类型'
        case 'database':
          return '数据库类型'
        case 'web':
          return '协议类型'
        default:
          return '设备类型'
      }
    }
  }
}
</script>
