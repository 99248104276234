<template>
  <a-card
    :body-style="{ padding: '0 48px 24px 24px' }"
    :bordered="false"
    :head-style="{ border: 'none' }"
    :hoverable="true"
    title="近一周告警严重性"
  >
    <a-row>
      <a-col v-for="(key, index) in Object.keys(count)" :key="index" style="margin-bottom: 8px;">
        <div>{{ $t(`severity.${key}`) }}</div>
        <a-tooltip placement="bottom" :title="$t(`severity.${key}`) + `: ${count[key]}`">
          <a-progress :percent="formatPercent(count[key])" size="small" status="normal" :strokeColor="strokeColor(key)" :strokeWidth="16"></a-progress>
        </a-tooltip>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import moment from 'moment'
import { getAlertEventCount } from '@/api/alert-event'
import { severityColor } from '@/utils'

const dateFormat = 'YYYY-MM-DD'

export default {
  name: 'AlertEventCard',
  props: {
    sourceType: { type: String, required: true }
  },
  data () {
    return {
      dateRange: [moment().subtract(1, 'week'), moment()],
      count: {
        disaster: 0,
        high: 0,
        average: 0,
        warning: 0
      },
      total: 0
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      const params = {
        date_from: this.dateRange[0].format(dateFormat),
        date_to: this.dateRange[1].format(dateFormat),
        source_type: this.sourceType,
        count_by: 'severity'
      }
      getAlertEventCount(params).then(res => {
        res.data.data.forEach(item => {
          this.count[item.name] = item.value
          this.total += item.value
        })
      })
    },
    formatPercent (value) {
      if (value) {
        return parseFloat((value / this.total * 100).toFixed(0))
      }
      return 0
    },
    strokeColor (severity) {
      return severityColor(severity)
    }
  }
}
</script>
